import React from 'react';

const ResultCard = ({ item, onClick }) => {
  const { name, address, rating, price_range, category, notes } = item;

  return (
    <div 
      className="bg-gray-800 shadow-md rounded-lg p-3 text-white cursor-pointer hover:bg-gray-700 transition-colors duration-200"
      onClick={() => onClick(item)}
    >
      <div className="flex flex-col h-full">
        <div className="flex justify-between items-start mb-2">
          <div>
            <h2 className="text-lg font-semibold text-white">{name}</h2>
            <p className="text-gray-400 text-sm">{address}</p>
            <div className="mt-1">
              <span className="text-yellow-400 text-sm">{rating} ★</span>
              <span className="ml-2 text-gray-400 text-sm">{price_range}</span>
            </div>
          </div>
          <div className="flex flex-wrap justify-end">
            <span className="bg-blue-600 text-white text-xs font-semibold px-2 py-0.5 rounded-full ml-1 mb-1">
              {category}
            </span>
          </div>
        </div>
        {notes && (
          <div className="mt-auto">
            <p className="text-gray-400 text-xs italic">{notes}</p>
          </div>
        )}
      </div>
    </div>
  );
};

export default ResultCard;
