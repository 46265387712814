import React, { useState, useEffect } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, IconButton, Typography, Link, Box, Tooltip, Snackbar, TextField, Button, Rating } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import BookmarkBorderIcon from '@mui/icons-material/BookmarkBorder';
import BookmarkIcon from '@mui/icons-material/Bookmark';
import ShareIcon from '@mui/icons-material/Share';

const DetailPopup = ({ item, onClose, supabase, user }) => {
  const [submitter, setSubmitter] = useState(null);
  const [isFavorite, setIsFavorite] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [comments, setComments] = useState([]);
  const [newComment, setNewComment] = useState('');
  const [newRating, setNewRating] = useState(0);
  const [shareSnackbarOpen, setShareSnackbarOpen] = useState(false);

  useEffect(() => {
    if (item && item.created_by) {
      fetchSubmitter(item.created_by);
    }
    if (item && user) {
      checkIfFavorite();
    }
    if (item && supabase) {
      fetchComments();
    }
  }, [item, user, supabase]);

  const fetchSubmitter = async (userId) => {
    const { data, error } = await supabase
      .from('profiles')
      .select('username')
      .eq('id', userId)
      .single();

    if (error) {
      console.error('Error fetching submitter:', error);
    } else {
      setSubmitter(data.username);
    }
  };

  const checkIfFavorite = async () => {
    if (!user || !item) return;

    try {
      let query = supabase
        .from('favorites')
        .select('*')
        .eq('user_id', user.id)
        .eq('item_type', item.category);

      // Handle different item_id types
      if (typeof item.id === 'string') {
        query = query.eq('item_id', item.id);
      } else {
        query = query.eq('item_id', item.id.toString());
      }

      const { data, error } = await query;

      if (error) {
        if (error.code !== 'PGRST116') {
          console.error('Error checking favorite:', error);
        }
        setIsFavorite(false);
      } else {
        setIsFavorite(data.length > 0);
      }
    } catch (error) {
      console.error('Error in checkIfFavorite:', error);
      setIsFavorite(false);
    }
  };

  const toggleFavorite = async () => {
    if (!user || !item) {
      console.error('User or item is not available');
      setSnackbarOpen(true);
      return;
    }

    console.log('Toggling favorite for:', {
      user_id: user.id,
      item_id: item.id,
      item_type: item.category,
      item_id_type: typeof item.id,
      full_item: item // Log the entire item object
    });

    try {
      if (isFavorite) {
        let query = supabase
          .from('favorites')
          .delete()
          .eq('user_id', user.id)
          .eq('item_type', item.category);

        // Handle different item_id types
        if (item.category === 'Attraction') {
          query = query.eq('item_id', item.id.toString());
        } else {
          query = query.eq('item_id', parseInt(item.id, 10));
        }

        const { error } = await query;

        if (error) throw error;

        setIsFavorite(false);
      } else {
        const { data, error } = await supabase
          .from('favorites')
          .insert({
            user_id: user.id,
            item_id: item.category === 'Attraction' ? item.id.toString() : parseInt(item.id, 10),
            item_type: item.category
          });

        console.log('Insert response:', { data, error });

        if (error) throw error;

        setIsFavorite(true);
      }

      setSnackbarOpen(true);
    } catch (error) {
      console.error('Error toggling favorite:', error);
    }
  };

  const getGoogleMapsUrl = (address) => {
    return `https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(address)}`;
  };

  const fetchComments = async () => {
    if (!item || !supabase) return;

    try {
      const { data, error } = await supabase
        .from('comments')
        .select('*')
        .eq('item_id', item.id.toString())
        .eq('item_type', item.category)
        .order('created_at', { ascending: false });

      if (error) throw error;

      setComments(data);

      // Fetch usernames for each comment
      const userIds = [...new Set(data.map(comment => comment.user_id))];
      const { data: profiles, error: profilesError } = await supabase
        .from('profiles')
        .select('id, username')
        .in('id', userIds);

      if (profilesError) throw profilesError;

      const userMap = Object.fromEntries(profiles.map(profile => [profile.id, profile.username]));

      setComments(data.map(comment => ({
        ...comment,
        username: userMap[comment.user_id] || 'Unknown User'
      })));
    } catch (error) {
      console.error('Error fetching comments:', error);
    }
  };

  const handleCommentSubmit = async (e) => {
    e.preventDefault();
    if (!user || !item || newComment.trim() === '' || newRating === 0) {
      setSnackbarMessage('Please fill in all fields and login to submit a comment.');
      setSnackbarOpen(true);
      return;
    }

    const { data, error } = await supabase
      .from('comments')
      .insert({
        user_id: user.id,
        item_id: item.id.toString(),
        item_type: item.category,
        rating: newRating,
        comment_text: newComment.trim()
      });

    if (error) {
      console.error('Error submitting comment:', error);
      setSnackbarMessage('Error submitting comment. Please try again.');
    } else {
      setSnackbarMessage('Comment submitted successfully!');
      setNewComment('');
      setNewRating(0);
      fetchComments();
    }
    setSnackbarOpen(true);
  };

  const handleShare = () => {
    if (item) {
      const shareUrl = `${window.location.origin}/search?category=${item.category.toLowerCase()}&id=${item.id}`;
      navigator.clipboard.writeText(shareUrl).then(() => {
        setShareSnackbarOpen(true);
      });
    }
  };

  return (
    <>
      <Dialog open={!!item} onClose={onClose} maxWidth="sm" fullWidth>
        {item && (
          <>
            <DialogTitle>
              {item.name}
              <Tooltip title="Share" arrow>
                <IconButton
                  onClick={handleShare}
                  sx={{
                    position: 'absolute',
                    right: 88,
                    top: 8,
                    color: (theme) => theme.palette.grey[500],
                  }}
                >
                  <ShareIcon />
                </IconButton>
              </Tooltip>
              <Tooltip title={user ? "Favorite" : "Login to favorite"} arrow>
                <span>
                  <IconButton
                    onClick={toggleFavorite}
                    disabled={!user}
                    sx={{
                      position: 'absolute',
                      right: 48,
                      top: 8,
                      color: (theme) => user ? theme.palette.grey[500] : theme.palette.grey[300],
                    }}
                  >
                    {isFavorite ? <BookmarkIcon /> : <BookmarkBorderIcon />}
                  </IconButton>
                </span>
              </Tooltip>
              <IconButton
                aria-label="close"
                onClick={onClose}
                sx={{
                  position: 'absolute',
                  right: 8,
                  top: 8,
                  color: (theme) => theme.palette.grey[500],
                }}
              >
                <CloseIcon />
              </IconButton>
            </DialogTitle>
            <DialogContent>
              {/* Location details */}
              <Typography variant="body1" gutterBottom>
                <Link
                  href={getGoogleMapsUrl(item.address)}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-blue-400 hover:text-blue-300"
                >
                  {item.address}
                </Link>
              </Typography>
              <Typography variant="body1" gutterBottom>
                Rating: {"★".repeat(item.rating) + "☆".repeat(5 - item.rating)}
              </Typography>
              {item.category === 'Hotel' && (
                <>
                  <Typography variant="body1" gutterBottom>
                    Price Range: {item.price_range}
                  </Typography>
                  <Typography variant="body1" gutterBottom>
                    Walk Rating: {"★".repeat(item.walk_rating) + "☆".repeat(5 - item.walk_rating)}
                  </Typography>
                  <Typography variant="body1" gutterBottom>
                    Internet Speed: {item.internet_speed}
                  </Typography>
                  {item.free_breakfast && (
                    <Typography variant="body1" gutterBottom className="text-green-500">
                      Free Breakfast
                    </Typography>
                  )}
                </>
              )}
              {(item.category === 'Restaurant' || item.category === 'Attraction') && (
                <Typography variant="body1" gutterBottom>
                  Price Range: {item.price_range}
                </Typography>
              )}
              {item.notes && (
                <Typography variant="body1" gutterBottom>
                  Notes: {item.notes}
                </Typography>
              )}
              {item.link && (
                <Typography variant="body1" gutterBottom>
                  <Link href={item.link} target="_blank" rel="noopener noreferrer" className="text-blue-400 hover:text-blue-300">
                    Visit Website
                  </Link>
                </Typography>
              )}
              {/* Comment submission form */}
              {user && (
                <Box component="form" onSubmit={handleCommentSubmit} sx={{ mt: 2 }}>
                  <Typography variant="h6" gutterBottom>
                    Add a Comment
                  </Typography>
                  <Rating
                    name="new-rating"
                    value={newRating}
                    onChange={(event, newValue) => {
                      setNewRating(newValue);
                    }}
                  />
                  <TextField
                    fullWidth
                    multiline
                    rows={3}
                    variant="outlined"
                    placeholder="Enter your comment (max 250 characters)"
                    value={newComment}
                    onChange={(e) => setNewComment(e.target.value)}
                    inputProps={{ maxLength: 250 }}
                    sx={{ mt: 1 }}
                  />
                  <Button type="submit" variant="contained" sx={{ mt: 1 }}>
                    Submit Comment
                  </Button>
                </Box>
              )}

              {/* Display existing comments */}
              <Box sx={{ mt: 3 }}>
                <Typography variant="h6" gutterBottom>
                  Comments
                </Typography>
                {comments.map((comment) => (
                  <Box key={comment.id} sx={{ mb: 3, p: 2, bgcolor: 'grey.800', borderRadius: 1 }}>
                    <Typography variant="subtitle2" sx={{ mb: 1 }}>
                      {comment.username} {"★".repeat(comment.rating) + "☆".repeat(5 - comment.rating)}
                    </Typography>
                    <Typography variant="body2" sx={{ mb: 1, pl: 1, borderLeft: '2px solid', borderColor: 'primary.main' }}>
                      {comment.comment_text}
                    </Typography>
                    <Typography variant="caption" color="text.secondary">
                      {new Date(comment.created_at).toLocaleString()}
                    </Typography>
                  </Box>
                ))}
              </Box>

              {/* Submitted by information */}
              {submitter && (
                <Typography variant="body2" color="textSecondary" sx={{ mt: 2, mb: 2 }}>
                  Submitted by: {submitter}
                </Typography>
              )}
            </DialogContent>
          </>
        )}
      </Dialog>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={3000}
        onClose={() => setSnackbarOpen(false)}
        message={snackbarMessage}
      />
      <Snackbar
        open={shareSnackbarOpen}
        autoHideDuration={3000}
        onClose={() => setShareSnackbarOpen(false)}
        message="Share link copied to clipboard"
      />
    </>
  );
};

export default DetailPopup;
