import React, { useState, useEffect } from 'react';
import { Typography, Box, CircularProgress } from '@mui/material';
import ResultCard from './ResultCard';

const RecentActivity = ({ supabase, onItemClick }) => {
  const [activities, setActivities] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    fetchRecentActivities();
  }, [supabase]);

  const fetchRecentActivities = async () => {
    try {
      const { data, error } = await supabase
        .from('recent_activity')
        .select(`
          *,
          profiles:user_id (username),
          hotels:item_id (*),
          restaurants:item_id (*),
          attractions:item_id (*)
        `)
        .order('created_at', { ascending: false })
        .limit(10);

      if (error) throw error;

      const formattedActivities = data.map(activity => ({
        ...activity,
        username: activity.profiles?.username || 'Unknown User',
        item: activity.hotels || activity.restaurants || activity.attractions,
        formattedDate: new Date(activity.created_at).toLocaleDateString()
      }));

      setActivities(formattedActivities);
    } catch (error) {
      console.error('Error fetching recent activities:', error);
    } finally {
      setIsLoading(false);
    }
  };

  if (isLoading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height="400px">
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box>
      <Typography variant="h6" gutterBottom>
        Recent Activity
      </Typography>
      {activities.length > 0 ? (
        activities.map((activity, index) => (
          <Box key={index} mb={2}>
            <Typography variant="body2" color="textSecondary" gutterBottom>
              {activity.action} by {activity.username} on {activity.formattedDate}
            </Typography>
            {activity.item && (
              <ResultCard 
                item={{...activity.item, category: activity.category}} 
                onClick={() => onItemClick({...activity.item, category: activity.category})} 
              />
            )}
          </Box>
        ))
      ) : (
        <Typography variant="body1">No recent activity to display.</Typography>
      )}
    </Box>
  );
};

export default RecentActivity;
