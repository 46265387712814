import React from 'react';
import { Accordion, AccordionSummary, AccordionDetails, Typography, Box } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const FAQ = () => {
  const faqData = [
    {
        question: "Slam Clicker (plural slam-clickers)",
        answer: "(slang, among flight attendants and pilots) An unsociable crewmember who prefers to stay in his or her hotel room between flights and 'slams the door and clicks the lock'."
      },
    {
      question: "What is Never Slam-Click?",
      answer: "Never Slam-Click is a platform for pilots to share and find information about hotels, restaurants, and attractions near airports. It helps pilots make informed decisions about where to stay and what to do during layovers."
    },
    {
      question: "How do I search for information?",
      answer: "You can search by entering an airport's ICAO or IATA code in the search bar. You can then filter results by category (hotels, restaurants, or attractions) and apply additional filters like price range or rating."
    },
    {
      question: "Can I contribute information to the platform?",
      answer: "Yes! We encourage users to submit their own experiences and recommendations. You can do this by clicking on the 'Submit' option in the navigation menu and filling out the form with details about a hotel, restaurant, or attraction."
    },
    {
      question: "Do I need an account to use Never Slam-Click?",
      answer: "While you can search and view information without an account, creating an account allows you to submit your own entries, save favorites, and keep track of your submissions. It's free to sign up!"
    }
  ];

  return (
    <Box className="max-w-2xl mx-auto">
      <Typography variant="h5" gutterBottom className="mb-10">
        Frequently Asked Questions
      </Typography>
      {faqData.map((faq, index) => (
        <Accordion key={index}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls={`panel${index + 1}-content`}
            id={`panel${index + 1}-header`}
          >
            <Typography>{faq.question}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>{faq.answer}</Typography>
          </AccordionDetails>
        </Accordion>
      ))}
    </Box>
  );
};

export default FAQ;
